import React, { useContext, useState } from 'react'
import { Formik, useField, useFormikContext } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import { AdminContext } from '../../../context/AdminContext';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../../index.css';
import axios from 'axios';

function FeaturedAnnouncementDialog({ dialogOpen, handleClose }) {
    const colors = tokens();
    const { token } = useContext(AdminContext);
    const [language, setLanguage] = useState("");

    var initialValues = {
        title: "",
        text: "",
        link: "",
        announcement_image: null,
        announcement_image_link: "",
        start_date: "",
        end_date: ""
    }

    var validationObject = {
        title: yup.string().required("required"),
        text: yup.string().required("required"),
        link: yup.string().required("required"),
        announcement_image: yup.mixed().nullable(),
        announcement_image_link: yup.string(),
        start_date: yup.date().required("required"),
        end_date: yup.date().required("required")
    }

    const announcementSchema = yup.object().shape(validationObject);


    const handleFormSubmit = async (values) => {
        if (language && language !== "") {
            if (values.announcement_image) {
                await axios.post(`${process.env.REACT_APP_API_URL}featuredAnnouncement/uploadAnnouncementPhoto?token=${token}`, {
                    announcementPhoto: values.announcement_image
                }, {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                })
                    .catch((err) => {
                        console.log("err: " + err);
                    })
                    .then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            values.announcement_image_path = response.data.photoPath;
                        }
                    });
            }
            var newValues = { ...values, start_date: values.start_date.toISOString().replaceAll("Z", ""), end_date: values.end_date.toISOString().replaceAll("Z", "") }
            delete newValues.id;
            delete newValues.announcement_image;
            delete newValues.announcement_image_link;
            delete newValues.announcement_image_created_date;
            delete newValues.created_date;
            delete newValues.language;
            console.log(newValues);
            await axios.patch(`${process.env.REACT_APP_API_URL}featuredAnnouncement/updateAnnouncement?language=${language}&token=${token}`, {
                featuredAnnouncement: newValues
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    setLanguage("");
                }
            });
        } else {
            handleClose();
            setLanguage("");
        }
    }

    const fetchAndSetAnnouncement = async (lang, setValues, setFieldValue) => {
        if (lang) {
            await axios.get(`${process.env.REACT_APP_API_URL}featuredAnnouncement/getAnnouncement?language=${lang}`).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    response.data.featuredAnnouncement.start_date = new Date(response.data.featuredAnnouncement.start_date)
                    response.data.featuredAnnouncement.end_date = new Date(response.data.featuredAnnouncement.end_date)
                    setValues(response.data.featuredAnnouncement);
                    setFieldValue("announcement_image", null);
                }
            });
        }
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={() => {
                handleClose();
                setLanguage("");
            }}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    Edit Featured Announcement
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={announcementSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setValues }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mb="25px">
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "2 / span 2", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Language</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Language"
                                            onChange={(e) => {
                                                setLanguage(e.target.value)
                                                fetchAndSetAnnouncement(e.target.value, setValues, setFieldValue);
                                            }}
                                            name={"language"}
                                            value={language}
                                        >
                                            <MenuItem value={""}>None</MenuItem>
                                            <MenuItem value={"turkish"}>Turkish</MenuItem>
                                            <MenuItem value={"french"}>French</MenuItem>
                                            <MenuItem value={"german"}>German</MenuItem>
                                            <MenuItem value={"spanish"}>Spanish</MenuItem>
                                            <MenuItem value={"portuguese"}>Portuguese</MenuItem>
                                            <MenuItem value={"italian"}>Italian</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                {language &&
                                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">


                                        <Box textAlign="center" sx={{ gridColumn: "2 / span 2", marginTop: "10px" }}>
                                            <Typography variant="h6">Announcement Image</Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                                {(values.announcement_image || (values.announcement_image_link && values.announcement_image_link !== "")) && <img alt='featured announcement' width="100px" src={values.announcement_image ? URL.createObjectURL(values.announcement_image) : values.announcement_image_link} />}
                                                <input id="announcement_image" name="announcement_image" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("announcement_image", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"title"}
                                            value={values.title}
                                            error={!!touched.title && !!errors.title}
                                            helperText={touched.title && errors.title}
                                            sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Text"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            multiline
                                            minRows={3}
                                            name={"text"}
                                            value={values.text}
                                            error={!!touched.text && !!errors.text}
                                            helperText={touched.text && errors.text}
                                            sx={{ gridColumn: "span 4", marginTop: "10px" }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Link"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"link"}
                                            value={values.link}
                                            error={!!touched.link && !!errors.link}
                                            helperText={touched.link && errors.link}
                                            sx={{ gridColumn: "span 4", marginTop: "10px" }}
                                        />

                                        <Box display="grid" gap="15px" rowGap={"5px"} gridTemplateColumns="repeat(4, minmax(0, 1fr))" gridColumn={"span 4"}>
                                            <Box sx={{ gridColumn: "span 2" }}>
                                                <InputLabel >Start Date</InputLabel>
                                                {!!errors?.start_date && <InputLabel sx={{ color: "#fc6565" }}>{errors.start_date}</InputLabel>}
                                            </Box>
                                            <Box sx={{ gridColumn: "span 2" }}>
                                                <InputLabel >End Date</InputLabel>
                                                {!!errors?.end_date && <InputLabel sx={{ color: "#fc6565" }}>{errors.end_date}</InputLabel>}
                                            </Box>
                                            <Box gridColumn={"span 2"}>
                                                <DatePickerField name="start_date" />
                                            </Box>
                                            <Box gridColumn={"span 2"}>
                                                <DatePickerField name="end_date" />
                                            </Box>
                                        </Box>

                                    </Box>
                                }
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

const DatePickerField = ({ ...props }) => {
    const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            autoComplete="off"
            onChange={val => {
                setFieldValue(field.name, val);
                setFieldTouched(field.name);
            }}
        />
    );
};

export default FeaturedAnnouncementDialog